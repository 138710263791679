"use client";

import type { PageContent } from "@/utils/i18n";
import type { Company, Listing, Location, Tag } from "@prisma/client";
import { useEffect } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useLocaleProvider } from "@/app/[lang]/LocaleProvider";
import { env } from "@/env.mjs";
import { type ListingSummarySectionContent } from "@/types/ListingSectionContent";
import { relativeFromNow } from "@/utils/dayjsUtils";
import { tExperience } from "@/utils/i18n";
import { shareOrCopy } from "@/utils/shareOrCopy";
import { renderReward } from "@/utils/string";
import { trpc } from "@/utils/TrpcProvider";
import { cn } from "@/utils/tw";
import { capitalize } from "radash";
import { Logo } from "../Logo/Logo";
import { Button } from "../ui/Button";
import { Chip } from "../ui/Chip";
import { Text } from "../ui/Text";
import { useToast } from "../ui/use-toast";
const t = {
  reward: {
    en: "Reward",
    no: "i belønning",
    dk: "i belønning"
  },
  expires: {
    en: "Expires",
    no: "Utløper",
    dk: "Udløber"
  },
  noExpirationDate: {
    en: "No expiration date",
    no: "Ingen utløpsdato",
    dk: "Ingen udløbsdato"
  },
  vouch: {
    en: "Vouch now",
    no: "Vouch nå",
    dk: "Vouch nu"
  },
  copied: {
    en: "Copied to clipboard",
    no: "Kopiert til utklippstavle",
    dk: "Kopieret til udklipsholder"
  }
} satisfies PageContent;
export type ListingWithCompany = Pick<Listing, "title" | "id" | "currency" | "bounty" | "publishedAt" | "updatedAt" | "slug" | "experience" | "expirationDate" | "createdAt" | "draft" | "summary" | "activated" | "language"> & {
  company: Pick<Company, "id" | "website" | "logoUrl" | "name" | "industries" | "description" | "slug" | "brandColor" | "language">;
  tags?: Pick<Tag, "id" | "name">[];
  locations?: Pick<Location, "id" | "slug" | "name" | "country">[];
}; // RouterOutputs["listings"]["getAll"][0];

export function ListingCard(props: {
  listing: ListingWithCompany;
  isSelected?: boolean;
  variant?: string;
  hideActions?: boolean;
  onChipClicked?: ListingCardDetailsProps["onChipClicked"];
}) {
  const {
    lang
  } = useLocaleProvider();
  const {
    toast
  } = useToast();
  const getReferral = trpc.misc.findReferral.useMutation();
  useEffect(() => {
    if (getReferral.data) {}
  }, [getReferral.data, props.listing, lang, toast]);
  return <Link href={`/listings/${props.listing.slug}`} className={cn("group block h-min w-full cursor-pointer overflow-hidden rounded-[30px] bg-beige-2 p-8 transition-all md:h-auto md:rounded-[60px] md:p-12")} data-sentry-element="Link" data-sentry-component="ListingCard" data-sentry-source-file="ListingCard.tsx">
      <div className="flex h-full flex-col gap-2">
        <div className="w-full flex-1">
          <ListingCardDetails listing={props.listing} onChipClicked={props.onChipClicked} hoverActions={<Button variant="ghost" icon="link" size="sm" onClick={async e => {
          e.preventDefault();
          e.stopPropagation();
          await shareOrCopy(getReferral.mutateAsync({
            type: "LISTING_SHARING",
            listingId: props.listing.id,
            companyId: props.listing.company.id
          }).then(data => ({
            message: `${env.NEXT_PUBLIC_APP_BASE_URL}/${lang}/listings/${props.listing.slug}?r=${data?.slug || ""}`
          })), true);
          toast({
            title: t.copied[lang]
          });
        }} />} data-sentry-element="ListingCardDetails" data-sentry-source-file="ListingCard.tsx" />
        </div>
        <div>
          <div className="flex items-center">
            <div className="flex w-full items-center gap-2">
              <Text.P data-sentry-element="unknown" data-sentry-source-file="ListingCard.tsx">
                {renderReward(lang, props.listing.currency, props.listing.bounty)} {t.reward[lang]}
              </Text.P>
            </div>
          </div>
          <div className="flex items-center">
            {props.listing.expirationDate ? <Text.P small light muted className="truncate">
                {t.expires[lang]} {relativeFromNow(lang, props.listing.expirationDate)}
              </Text.P> : <Text.P small light muted>
                {t.noExpirationDate[lang]}
              </Text.P>}
          </div>
        </div>
      </div>
    </Link>;
}
export type ListingCardDetailsProps = {
  listing: ListingWithCompany;
  className?: string;
  hideTags?: boolean;
  onChipClicked?: (type: "tag", item: {
    id: string;
    value: string;
  }) => void;
  hoverActions?: React.ReactNode;
};
export const ListingCardDetails = (props: ListingCardDetailsProps) => {
  const locationsCount = props.listing.locations?.length || 0;
  const {
    lang
  } = useLocaleProvider();
  const router = useRouter();
  const tags = (props.listing.summary as ListingSummarySectionContent)?.highlights;
  return <div className={cn("flex flex-col rounded-lg", props.className)} data-sentry-component="ListingCardDetails" data-sentry-source-file="ListingCard.tsx">
      <div className="mb-2 flex">
        <div className="mb-2 flex flex-1">
          <Logo name={props.listing.company.name || ""} className="mr-4 h-[46px] w-[46px] cursor-pointer" logoUrl={props.listing.company.logoUrl} onClick={() => {
          router.push(`/company/${props.listing.company.slug}`);
        }} data-sentry-element="Logo" data-sentry-source-file="ListingCard.tsx" />
          <div className="flex-1">
            <Text.P className="cursor-pointer" onClick={() => {
            router.push(`/company/${props.listing.company.slug}`);
          }} data-sentry-element="unknown" data-sentry-source-file="ListingCard.tsx">
              {props.listing.company.name}
            </Text.P>
            <div className="flex items-center rounded-full">
              <Text.P muted small data-sentry-element="unknown" data-sentry-source-file="ListingCard.tsx">
                {props.listing.locations?.slice(0, 3)?.map(l => l.name)?.join(" | ") + (locationsCount > 3 ? ` | +${locationsCount - 3}` : "")}
              </Text.P>
            </div>
          </div>
        </div>
        <div className="hidden items-center gap-4 self-start transition-all lg:flex lg:opacity-0 lg:group-hover:opacity-100">
          {props.hoverActions}
        </div>
      </div>
      <Text.H6 data-sentry-element="unknown" data-sentry-source-file="ListingCard.tsx">
        {props.listing.title.endsWith(props.listing.company.name) ? props.listing.title.replace(props.listing.company.name, "") : props.listing.title}
      </Text.H6>
      {!props.hideTags && <div className="mt-2 flex w-full flex-wrap gap-2 overflow-hidden">
          {tags?.slice(0, 4)?.map(tag => <Chip rounded variant="purple" key={tag}>
              {tag}
            </Chip>)}
          {props.listing.experience.slice(0, 4)?.map(tag => <Chip rounded variant="purple" key={tag}>
              {tExperience[lang][tag]}
            </Chip>)}
          {props.listing.tags?.slice(0, 4).map(tag => <Chip rounded variant="purple" key={tag.name} onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        props.onChipClicked?.("tag", {
          id: tag.id,
          value: tag.name
        });
      }}>
              {capitalize(tag.name)}
            </Chip>)}
          {(props.listing.tags || []).length > 4 && <Chip rounded variant="purple" key={"more"}>
              <Text.P className="truncate" muted small>
                + {(props.listing.tags || []).length - 4}
              </Text.P>
            </Chip>}
        </div>}
    </div>;
};